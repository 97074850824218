// JavaScript Document
/* Item List */
function itemli(callback,directory = "",append,loop){
var list = "";
    $.ajax({
        url: '//www.kontatsu.co.jp/wp/wp-content/themes/kontatsu-child/assets/json/' + directory + callback + '.jsonp',
        type:"GET",
        dataType:"jsonp",
        jsonpCallback: callback,
        error:function() {
//            alert("ロード失敗");
        },
        success: function(json){
	 	var len = json.length;

        var display_num = "";
        if(loop) {
            var display_num = loop;
        } else {
            var display_num = len;
        }

        var caption = "";
    		for(var i=0; i < display_num; i++){

                if(json[i].brand){
                    brand = '<p class="brand">' + json[i].brand + '</p>'
                } else {
                    brand = ''
                }

                if(json[i].cap){
                    caption = '<span class="caption">' + json[i].cap + '</span>'
                } else {
                    caption = ''
                }

                if(json[i].price){
                    price = '<p class="price">' + json[i].price + '<span>円</span></p>'
                } else {
                    price = ''
                }

				if(json[i].url_m != ""){
					list = '<li><a href="' + json[i].url_m + '" target="_top"><div class="imgArea"><img src="' + json[i].img + '" alt="' + json[i].name + '"></div><div class="txtArea">' + brand + '<p class="name abbr">' + json[i].name + '</p>' + caption + price + '</div></a></li>';
				} else {
					list = '';
				}
   				$(append).append(list);
   			}
		}
    })
};